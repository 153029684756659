import { makeObservable, observable, runInAction, reaction } from 'mobx'
import { debounce } from 'lodash'
import { getStores, getStore, saveStore, deleteStore, activateStore, deactivateStore, getStatuses } from '../api'
import type { StoresStore, Store, PaginationFilter, AppStore, StoresResponse, StatusesResponse } from './types'

class StoresStoreImpl implements StoresStore {
  filter: PaginationFilter = {
    page: 1,
    pageSize: 20
  }

  totalItems: number = 0
  items: Store[] = []
  statusMap: Record<string, string> = {}

  constructor (private readonly root: AppStore) {
    makeObservable<StoresStore, 'filter'>(this, {
      filter: observable,
      totalItems: observable,
      items: observable,
      statusMap: observable
    })

    this.filterHandler()
    // this.intervalHandler()
  }

  setFilter = (filter: Partial<PaginationFilter>): void => {
    runInAction(() => {
      this.filter = {
        ...this.filter,
        ...filter
      }
    })
  }

  loadStores = async (): Promise<void> => {
    const response = await this.root.tryToFetch<StoresResponse>(getStores(this.filter, this.root.user.token ?? ''))
    if (response !== null) {
      const { items, count } = response
      await this.loadStatuses(items.map(v => v.dockerServiceId))

      runInAction(() => {
        this.items = items
        this.totalItems = count
      })
    }
  }

  readonly loadStatuses = async (dockerServiceId: string[]): Promise<void> => {
    const response = await this.root.tryToFetch<StatusesResponse>(getStatuses(dockerServiceId, this.root.user.token ?? ''))
    if (response !== null) {
      runInAction(() => {
        this.statusMap = response.reduce((acc, v) => ({
          ...acc,
          [v.dockerServiceId]: v.value
        }), {})
      })
    }
  }

  getStore = async (storeId: string): Promise<Store | null> => {
    const result = await this.root.tryToFetch<Store>(getStore(Number(storeId), this.root.user.token ?? ''))

    return result
  }

  deleteStore = async (storeId: string): Promise<void> => {
    await this.root.tryToFetch(deleteStore(Number(storeId), this.root.user.token ?? ''))
  }

  saveStore = async (store: Partial<Store>): Promise<void> => {
    const response = await this.root.tryToFetch<Store>(saveStore(store, this.root.user.token ?? ''))

    if (response === null) {
      throw new Error('Save failed')
    }
  }

  activateStore = async (store: Store): Promise<void> => {
    try {
      await this.root.tryToFetch<Store>(activateStore(store.id, this.root.user.token ?? ''))
    } catch (e) {
      throw new Error('Activation failed')
    }
  }

  deactivateStore = async (store: Store): Promise<void> => {
    try {
      await this.root.tryToFetch<Store>(deactivateStore(store.id, this.root.user.token ?? ''))
    } catch (e) {
      throw new Error('Deactivation failed')
    }
  }

  private filterHandler (): void {
    reaction(
      () => this.filter,
      debounce(() => {
        void this.loadStores()
      }, 300)
    )
  }

  private intervalHandler (): void {
    setInterval(() => {
      if (this.items.length > 0) {
        void this.loadStatuses(this.items.map(v => v.subdomain))
      }
    }, 50000)
  }
}

export function createStoresStore (root: AppStore): StoresStore {
  return new StoresStoreImpl(root)
}
